import { store as auth } from '@/modules/auth'
import { store as event } from '@/pages/event'
import { store as users } from '@/pages/users'
import { store as roles } from '@/pages/roles'
import { store as sympla } from '@/pages/event/sympla'
import { store as emailTemplate } from '@/pages/event/emailTemplate'
import { store as speaker } from '@/pages/speakers'
import { store as schedule } from '@/pages/schedules'
import { store as room } from '@/pages/rooms'
import { store as partner } from '@/pages/partners'
import { store as partnerVideos } from '@/pages/partners/videos'
import { store as partnerArchives } from '@/pages/partners/archives'
import { store as partnerProducts } from '@/pages/partners/products'
import { store as partnerContacts } from '@/pages/partners/contacts'
import { store as asking } from '@/pages/rooms/askings'
import { store as quiz } from '@/pages/rooms/quiz'
import { store as poll } from '@/pages/rooms/poll'
import { store as streaming } from '@/pages/rooms/transmission'
import { store as callAction } from '@/pages/rooms/call-action'
import { store as wordCloud } from '@/pages/rooms/word-cloud'
import { store as participant } from '@/pages/participants'
import { store as survey } from '@/pages/survey'
import { store as gamification } from '@/pages/gamification'
import { store as archive } from '@/pages/archives'
import { store as video } from '@/pages/videos'
import { store as timelineBanner } from '@/pages/event/timelineBanners'
import { store as indicationReward } from '@/pages/event/indication'
import { store as raffle } from '@/pages/raffle'
import dashboards from '@/modules/dashboards/store'

export default {
  auth,
  event,
  users,
  roles,
  sympla,
  emailTemplate,
  speaker,
  schedule,
  room,
  asking,
  quiz,
  poll,
  streaming,
  callAction,
  wordCloud,
  participant,
  survey,
  gamification,
  archive,
  video,
  raffle,
  partner,
  partnerVideos,
  partnerArchives,
  partnerProducts,
  partnerContacts,
  timelineBanner,
  indicationReward,
  dashboards
}
