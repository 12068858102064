import http from '@/http'
const URL = '/admin/users'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=rooms`))
  }
  return one
}

export const saveUser = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const importUsers = (payload) => {
  return http.post(URL + '/import', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const changeModerator = (payload) => http.put(URL + `/${payload}/moderator`)

export const deleteUser = async (id) => http.delete(URL + `/${id}`)

export const exportData = async (params) => {
  const data = (await http.get(URL + '/export', { params: params, responseType: 'blob' }))
  return data
}

export default {
  list,
  show,
  saveUser,
  importUsers,
  changeStatus,
  deleteUser,
  exportData,
  changeModerator
}
