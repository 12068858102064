import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {
    description: ''
  }
})

export default {
  [types.SET_RAFFLE] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.raffle = payload
  },
  [types.SET_RAFFLES] (state, payload) {
    state.raffles = []
    if (payload.data.length > 0) {
      for (let raffle of payload.data) {
        convertIntToBoolean(raffle, ['status'])
      }
      state.raffles = payload
    }
  }
}
