import http from '@/http'
const URL = '/admin/settings/timeline'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveBanner = (payload) => {
  let id = ''
  if (payload.get('id') > 0) {
    id = payload.get('id')
  }
  return http.post(URL + `/${id}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export const deleteVideo = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  show,
  orderList,
  saveBanner,
  changeStatus,
  deleteVideo
}
