export default [
  {
    name: 'rooms',
    path: '/rooms',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Salas',
      icon: 'dvr',
      label: 'Salas'
    }
  },
  {
    name: 'room-edit',
    path: '/rooms/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Sala - Edição',
      icon: 'dvr',
      label: 'Sala - Edição'
    }
  },
  {
    name: 'room-show',
    path: '/rooms/:id/details',
    component: () => import(/* webpackChunkName: "event" */ './Show'),
    meta: {
      showNavbar: false,
      title: 'Sala',
      icon: 'dvr',
      label: 'Sala'
    },
    children: [
      { path: '/rooms/:id/details/transmission', component: () => import(/* webpackChunkName: "event" */ './transmission/Index'), name: 'room-transmission' },
      { path: '/rooms/:id/details/chat', component: () => import(/* webpackChunkName: "event" */ './chat/Index'), name: 'room-chat' },
      { path: '/rooms/:id/details/askings', component: () => import(/* webpackChunkName: "event" */ './askings/Index'), name: 'room-askings' },
      { path: '/rooms/:id/details/quiz', component: () => import(/* webpackChunkName: "event" */ './quiz/Index'), name: 'room-quiz' },
      { path: '/rooms/:id/details/poll', component: () => import(/* webpackChunkName: "event" */ './poll/Index'), name: 'room-poll' },
      { path: '/rooms/:id/details/word-cloud', component: () => import(/* webpackChunkName: "event" */ './word-cloud/Index'), name: 'room-word-cloud' },
      { path: '/rooms/:id/details/call-to-action', component: () => import(/* webpackChunkName: "event" */ './call-action/Index'), name: 'room-call-to-action' }
    ]
  },
  {
    name: 'room-new',
    path: '/rooms/novo',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Sala - Adicionar',
      icon: 'dvr',
      label: 'Sala - Adicionar'
    }
  },
  { path: '/rooms/:id/word-cloud/:wordCloudId', component: () => import(/* webpackChunkName: "event" */ './word-cloud/FullWordCloud'), name: 'room-word-result' },
  { path: '/poll/:pollId/chart', component: () => import(/* webpackChunkName: "event" */ './poll/FullChart'), name: 'room-poll-chart' },
  { path: '/quiz/:quizId/chart', component: () => import(/* webpackChunkName: "event" */ './quiz/FullChart'), name: 'room-quiz-chart' }
]
