import http from '@/http'
const URL = '/admin/raffle'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const store = (payload) => {
  return http.post(URL, payload)
}

export default {
  list,
  store
}
