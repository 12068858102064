export default {
  setting: {},
  sympla: {
    token: ''
  },
  zoom: {
    zoom_api_key: '',
    zoom_api_secret: ''
  }
}
