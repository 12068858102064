import { convertIntToBoolean } from '@/helpers'

export default {
  'ROOM' (state) {
    return state.room
  },
  'SETTING' (state) {
    if (state.room.settings) {
      let setting = convertIntToBoolean(state.room.settings, [
        'chat',
        'asking',
        'poll',
        'emoji',
        'word_cloud',
        'call_action',
        'quiz'
      ])
      return setting
    }
    return {}
  },
  'ASKINGS' (state) {
    if (state.room.askings) {
      return state.room.askings.data
    }
    return []
  },
  'ROOMS' (state) {
    return state.rooms
  },
  'CHAT' (state) {
    return state.chat
  }
}
