import http from '@/http'
const URL = '/admin/settings'

export const setting = () => http.get(URL + '?include=custom')
export const symplaToken = () => http.get(URL + '/sympla')
export const zoomKeys = () => http.get(URL + '/zoom')
export const saveSetting = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
export const saveIntegration = (payload) => http.post(URL + `/${payload.id}`, payload)
export const sendNotification = (payload) => http.post(URL + `/pusher`, payload)
export const saveToken = (payload) => http.post(URL + `/sympla`, payload)
export const saveKeys = (payload) => http.post(URL + `/zoom`, payload)
export const changeEmoji = (payload) => http.post(URL + `/changeEmoji`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
export const changeColors = (payload) => http.post(URL + `/${payload.id}`, payload)
export const saveTerms = (payload) => http.post(URL + `/terms`, payload)
export const getTerms = () => http.get(URL + `/terms`)
export const deletePage = async (payload) => http.delete(URL + `/cert/${payload.id}/page`)

export default {
  setting,
  symplaToken,
  zoomKeys,
  saveToken,
  saveKeys,
  saveSetting,
  saveIntegration,
  sendNotification,
  changeEmoji,
  changeColors,
  saveTerms,
  getTerms,
  deletePage
}
